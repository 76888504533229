import request from "@/utils/request";

export function plcStatus(params) {
  return request({ url: `/home/plc_status/`, method: "get", params });
}

export function plcStart(data) {
  return request({ url: `/home/plc_start/`, method: "post", data });
}

export function homeOverview(params) {
  return request({ url: `/home/overview/`, method: "get", params });
}

export function getMeasurementStatus(params) {
  return request({ url: `/home/get_measurement_status/`, method: "get", params });
}

export function micangUploadStatus(params) {
  return request({ url: `/home/micang_upload_status/`, method: "get", params });
}

export function sheinUploadStatus(params) {
  return request({ url: `/home/shein_upload_status/`, method: "get", params });
}
