<template>
  <div>
    <a-layout v-if="isLogin">
      <a-layout-sider class="sidebar" width="256" v-model="collapsed" :trigger="null" collapsible>
        <sidebar :collapsed="collapsed" />
      </a-layout-sider>

      <a-layout>
        <a-layout-header class="headbar">
          <headbar :collapsed="collapsed" :username="username" @toggleCollapsed="toggleCollapsed" />
        </a-layout-header>

        <a-layout-content>
          <tab-layout />
          <a-alert v-if="latestMeasurementStatus === 'closed'" message="DWS检验不合格, 过机数据无效" type="warning" show-icon />
          <router-view v-if="isRouterAlive" style="padding: 8px" />
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import { getInfo } from "@/apis/system";
import Cookies from "js-cookie";
import { getMeasurementStatus } from "@/apis/stats";

export default {
  name: "BaseLayout",
  components: {
    Headbar: () => import("@/components/Headbar"),
    Sidebar: () => import("@/components/Sidebar"),
    TabLayout: () => import("@/components/TabLayout"),
  },
  provide() {
    return {
      reloadPage: () => {
        return this.reloadPage();
      },
    };
  },
  data() {
    return {
      isLogin: false,
      collapsed: false,
      isRouterAlive: true,

      intervalId: null,
      latestMeasurementStatus: null,
    };
  },
  computed: {
    username() {
      return this.$store.state.user.username;
    },
    isManager() {
      return this.$store.state.user.isManager;
    },
    permissions() {
      return this.$store.state.user.permissions;
    },
  },
  methods: {
    initialize() {
      if (!Cookies.get("access") || !Cookies.get("refresh")) {
        return this.$router.push("/user/login");
      }

      getInfo().then((data) => {
        this.isLogin = true;
        this.$store.commit("setUser", data);
      });
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    },
    reloadPage() {
      this.isRouterAlive = false;
      this.$nextTick(() => (this.isRouterAlive = true));
    },
    checkStatus() {
      getMeasurementStatus().then((data) => {
        this.latestMeasurementStatus = data.latest_measurement_status;
      });
    },
  },
  mounted() {
    this.initialize();

    this.checkStatus();
    this.intervalId = setInterval(() => {
      this.checkStatus();
    }, 3000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>

<style scoped>
.headbar {
  background: #fff;
  padding: 0;
  box-shadow: 0px 1px 10px #7774;
}

.sidebar {
  background: #fff;
  overflow: auto;
  box-shadow: 1px 0px 10px #7774;
}
</style>
